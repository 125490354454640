/* work.module.css */
.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    width: 70%;
}

.slider div {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 500px; 
}

.slider img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; 
}

/* Adjustments for screen width of 1080 pixels or below */
@media (max-width: 1080px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }


    /* Additional adjustment to reduce space above the slider */
    .workT {
        text-align: center;
        margin-bottom: 0px;
        padding-bottom: 0; /* Reduce bottom margin of the title */

    }

    .root {
        gap: 10px; /* Reduce space between flex items if needed */
    }


}

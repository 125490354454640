

.container{
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items:center;
    justify-content:space-evenly;
}

a img{
    width: 40px;
    margin-right: 2%;
    height: 40px;
}

.root{
   display: flex;
   flex-direction: row;
   justify-content: center;
}

#desktop{
    width: 50%;

}

.about{
    width: 50%;
}

#aboutP{
    line-height: 2;
    padding: none;
}



span{
    color: #0051bb;
}


@media (max-width: 900px){
    .container{
        display: flex;
        flex-direction: column;
    }
    .about{
        width: fit-content;
    }
    #desktop{
        width: 100%;
    
    }
}

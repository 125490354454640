.root {
    display: flex;
    justify-content: center;
    background-color: #d3e6ff;
    padding: 3%;
    margin-top: 3%;
}

.container {
  width: 70%;
}

.exp {
    margin-top: 4%;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    width: 100%; 
    gap: 4%;

}

.exp1, .exp2, .exp3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 33%; 
    padding: 2%;
    line-height: 1.8;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    text-align: center;
}

h3{
    margin-bottom: .5;
}

p{
    margin-top: 0;
}


@media (max-width:900px){
    .exp{
        flex-direction: column;
    
    }

    .exp1, .exp2, .exp3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%; 
        padding: 2%;
        line-height: 1.8;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        text-align: center;
        margin: 2%;
    }
}

.image{
    width: 25%;
    height: 25%;
}

.container{
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.root{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:1080px){
    .container{
        flex-direction: column;
    }
    .container img{
        width: 100%;
    }
    
}

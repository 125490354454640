.currentWorks {
    width: auto;
}

.imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.currentHeader, .subheader {
    text-align: center;
}

.currentHeader{
    padding-top: 50px;
}

.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content {
    width: 100%; 
}

@media (max-width: 1080px) {
    .currentWorks{
        width: 85%;
    }
}

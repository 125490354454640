a{
    text-decoration: none;
    color: inherit;
    line-height: 2;
}

img{
    width: 33%;

}

.container{
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    align-items: stretch;
    padding: 3%;
}

.root{
    display: flex;
    justify-content: center;
}

p{
    line-height: 1;
}

@media (max-width:900px){
    .container{
        flex-direction: column;
    }
    
}
